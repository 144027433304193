@import url("https://fonts.googleapis.com/css?family=Lato");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

$font1: 'Lato';
$font2: 'Open Sans', sans-serif; 
$heading: #050552;
$subHeading: rgba(5, 5, 82, 0.8);
$subText: rgba(5, 5, 82, 0.6);
$hover: rgba(85, 78, 241, 0.1);
$primaryColor: #554ef1;
$footer: rgba(5, 5, 82, 0.03);
$background: #fff;
$backdrop: rgba(7, 8, 23, 0.85);

.app {
  border-radius: 5px;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $backdrop !important;
}
