@import "../../App.scss";

%main_heading {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: $heading;
  padding: 0;
  margin: 0;
}

#redirect {
  &__msg {
    font-size: 14px;
    line-height: 1.71;
    font-style: italic;
    color: $primaryColor;
  }
}

#footer {
  &__container {
    height: 3.5em;
    display: flex;
    background-color: $footer;
    justify-content: space-between;
    align-items: center;
    padding: 0 2.5em;
  }
  
  &__item_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-size: 12px;
      color: $subHeading;
      margin-left: 0.6em;
    }
  }
}

#animation {
  svg {
    height: 22em !important;
    width: 22em !important;
  }
}

#response_modal {
  &__container {
    height: inherit;
  }

  &__sub_container {
    height: calc(100% - 1.5em);
    padding: 5.75em 1.72em 2em 1.72em;

    @media only screen and (max-height:600px) and (min-width: 320px) and (max-width: 1025px) {
      height: calc(100% - 3.5em);
    }
  }

  &__heading {
    @extend %main_heading;
    margin-bottom: 1.2em;
  }

  &__subText {
    font-size: 14px;
    color: $subHeading;
    line-height: 1.71;
    text-align: left;
  }

  &__redirect{
    font-size: 14px;
    line-height: 1.71;
    font-style: italic;
    color: $primaryColor;
  }
}