@import '../App.scss';

.hv-modal {
  background-color: transparent;
}

#kyc { 
  &_container {
    color : $heading;
    font-size: 14px;
    font-weight: normal;
    max-height: 100vh;
    width: 350px;
    height: 623px;
    overflow: hidden;
    border-radius: 5px;
    background-color: $background;

    @media only screen and (max-device-width: 480px) {
      height: 100%;
      width: 100%;
    }
  }
}
