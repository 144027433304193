@import "../../App.scss";

@mixin removeMargin() {
    margin: 0;
}

#landing_page {
    &__container {
        background-color: white;
        min-height: 100vh;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        border-bottom: 13px solid darkblue;
        padding: 20px;
        padding-bottom: 10px;

        @media only screen and (max-width : 600px) {
            border-bottom: 5px solid darkblue;
        }

        >img {
            width: auto;
            height: auto;
            max-height: 70px;
            margin: 0 auto;

            @media only screen and (max-width : 1824px) {
                max-height: 70px;
            }

            @media only screen and (max-width : 1444px) {
                max-height: 50px;
            }

            @media only screen and (max-width : 600px) {
                height: auto;
                width: 100%;
                max-height: unset;
            }
        }
    }

    &__content {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 20px;
    }

    &__left {
        flex: 0 1 auto;
        // margin-right: 20px;
        display: flex;
        flex-direction: column;
        
        @media only screen and (max-width : 820px) {
            flex: 1 1 auto;
        }

        &__header {
            display: flex;
            flex-direction: column;

            @media only screen and (max-width : 600px) {
                align-items: center;
                text-align: center;
            }

            h1 {
                font-family: $font2 !important;
                color: #053B83;
                @include removeMargin();
                line-height: 1.1em;
            }

            h3 {
                font-family: $font1 !important;
                color: #053B83;
                @include removeMargin();
                margin-top: 10px;
            }
        }

        &__content {
            margin-top: 15px;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width : 600px) {
                align-items: center;
                text-align: center;
            }

            p {
                font-family: $font1 !important;
                color: #333333;
                max-width: 51ch;
                line-height: 1.5em;
            }
        }

        &__buttons {
            margin-top: 22px;
            display: flex;
            flex-direction: row;

            @media only screen and (max-width : 820px) {
                justify-content: center;
                text-align: center;
            }

            flex-wrap: wrap;
            gap: 16px;
            font-family: $font2 !important;
        }

    }

    &__right {
        flex: 1 1 200px;
        display: flex;
        margin-left: 10px;

        &__image {
            min-width: 300px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;

            
            @media only screen and (max-width : 820px) {
                min-width: unset;
                display: none;
            }

            >img {
                width: 100%;
                height: auto;
            }

        }
    }

}

#button_card {
    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px;
        border: 1px solid black;
        border-radius: 5px;
        width: 300px;
        cursor: pointer;

        &:hover {
            background-color: #e3e3e3;
        }

        @media only screen and (max-width : 600px) {
            width: 100%;
            min-width: 300px;
        }

        @media only screen and (max-width : 320px) {
            width: 100%;
            min-width: 200px;
        }

        >img {
            width: 150px;
            height: 150px;
        }

        >h1 {
            font-size: 16px;
            color: #333333;
            @include removeMargin();
            margin-top: 8px;
            font-weight: bold;
        }

        >p {
            font-size: 16px;
            color: #333333;
            @include removeMargin();
            margin-top: 16px;
            text-align: center;
        }
    }
}